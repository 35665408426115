import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/agriculture/sprayers/john-deere-sprayers/',
    imageId: 'deereSprayersImage',
    name: 'John Deere Sprayers',
    alt: 'John Deere Sprayers',
  },
  {
    link: '/agriculture/sprayers/hagie-sprayers/',
    imageId: 'hagieSprayersImage',
    name: 'Hagie Sprayers',
    alt: 'Hagie Sprayers',
  },
  {
    link: '/agriculture/sprayers/floaters/',
    imageId: 'floaterImage',
    name: 'Floaters',
    alt: 'Floater',
  },
]

const SprayersPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage, sprayerPUKImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Sprayers | Hutson Inc</title>
        <meta
          name='description'
          content="Check out Hutson's full line of John Deere and Hagie sprayers. These sprayers are equipped with the latest technology so you can spray your crop with confidence."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Sprayers',
                'item': 'https://www.hutsoninc.com/agriculture/sprayers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Sprayers' />

      <Content>
        <Floater items={gridItems} />

        {promos.length > 0 && <Promos data={promos} type='Sprayer' />}

        <Videos type='Sprayer' id='TKzO7u9OLX0' list='PLUz5fj7f_mw-VWt-cyCrwLTZYp6e-dYZ9' />
      </Content>
      <FlexGrid>
        <div>
          <GatsbyImage
            image={getImage(sprayerPUKImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridTextColumn>
          <H3 as='h2'>Sprayer Precision Upgrades</H3>
          <P>
            Add value to your existing sprayer with upgrades like ExactApply™, which minimizes
            over-application and crop burn, and Individual Nozzle Control Pro, which provides
            precise application and reduces chemical waste.
          </P>
          <div>
            <Button
              as={ButtonOutboundLink}
              color='green'
              ghost
              href='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/sprayer-upgrades/'
            >
              Learn more at Deere.com
            </Button>
          </div>
        </FlexGridTextColumn>
      </FlexGrid>
    </Layout>
  )
}

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  background-color: ${props => props.theme.color.n20};
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const ButtonOutboundLink = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0;
  text-decoration: none;
`

export const pageQuery = graphql`
  query sprayersSubcategoryQuery($subcategory: String = "sprayers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/sprayers-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    floaterImage: file(relativePath: { eq: "agriculture/f4365-floater.jpg" }) {
      ...FloatingGridImage
    }
    deereSprayersImage: file(relativePath: { eq: "agriculture/john-deere-sprayers.jpg" }) {
      ...FloatingGridImage
    }
    hagieSprayersImage: file(relativePath: { eq: "agriculture/hagie-sprayers.jpg" }) {
      ...FloatingGridImage
    }
    sprayerPUKImage: file(relativePath: { eq: "precision-upgrades/exact-apply.jpg" }) {
      ...SharpImage900
    }
  }
`

export default SprayersPage
